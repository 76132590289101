<template>
  <div>
    <fieldset style="width: 97%">
      <legend>搜索信息</legend>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item style="margin-left: 10px">
          <el-date-picker
              v-model="form.declarationDateList"
              type="daterange"
              range-separator="至"
              start-placeholder="申报日期"
              end-placeholder="申报日期"
              value-format="yyyy-MM-dd"
          clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-date-picker
              v-model="form.declarePaymentDateList"
              type="daterange"
              range-separator="至"
              start-placeholder="申报支付日期"
              end-placeholder="申报支付日期"
              value-format="yyyy-MM-dd"
              clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-date-picker
              v-model="form.createDateList"
              type="daterange"
              range-separator="至"
              start-placeholder="支出日期"
              end-placeholder="支出日期"
              value-format="yyyy-MM-dd"
              clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-date-picker
              v-model="form.settlementDateList"
              type="daterange"
              range-separator="至"
              start-placeholder="公司结算时间"
              end-placeholder="公司结算时间"
              value-format="yyyy-MM-dd"
            clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-input v-model="form.content" placeholder="内容" clearable></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-select v-model="form.spendProjectsIds" multiple filterable placeholder="支出项目" clearable>
            <el-option
                v-for="item in projectsArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-select v-model="form.shopIds" multiple filterable placeholder="店铺" clearable>
            <el-option
                v-for="item in shops"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-input v-model="form.spendPeople" placeholder="报销人"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-select v-model="form.settlement" filterable placeholder="状态" clearable>
            <el-option
                v-for="item in state"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-select v-model="form.spendMethodIds" multiple filterable placeholder="公司结算方式" clearable>
            <el-option
                v-for="item in spendMethodArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-select v-model="form.declareMethodIds" multiple filterable placeholder="申报支付方式" clearable>
            <el-option
                v-for="item in declareMethodArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <br>
        <el-form-item style="margin-left: 10px">
          <el-button type="primary" @click="querySpendListByCondition">查询</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-button type="danger" @click="deleteBatch">批量删除</el-button>
        </el-form-item>
      </el-form>
    </fieldset>

    <el-row style="margin-top: 30px">
      <el-col :span="24">
        <el-table
            ref="multipleTable"
            :data="this.table.tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @select="handleSelect"
            @select-all="handleSelect"
            :border="true"
            max-height="650"
            :highlight-current-row="true"
            :header-cell-style="{'background-color': '#F1F4FF' ,'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column
              type="selection"
              width="55"
              fixed="left">
          </el-table-column>

          <el-table-column
              label="支出发生日期"
              width="120">
            <template slot-scope="scope">{{ scope.row.createDate }}</template>
          </el-table-column>
<!--          内容这不允许换行-->
          <el-table-column
              prop="content"
              label="内容"
              width="200"
              :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
              prop="spendProjectsName"
              label="支出项目"
              width="150">
          </el-table-column>
          <el-table-column
              prop="shopName"
              label="店铺"
              width="120">
          </el-table-column>
          <el-table-column
              prop="spendPeopleName"
              label="报销人"
              width="120">
          </el-table-column>
          <el-table-column
              prop="spendAmount"
              label="支出金额"
              width="120">
          </el-table-column>
<!--          <el-table-column-->
<!--              label="状态"-->
<!--              width="120">-->
<!--            <template slot-scope="scope">-->
<!--              <el-tag type="success" v-if="scope.row.settlement === 0">预支</el-tag>-->
<!--              <el-tag type="primary" v-if="scope.row.settlement === 1">结算</el-tag>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column
              prop="spendMethodName"
              label="公司结算方式"
              width="120">
          </el-table-column>
          <el-table-column
              prop="spendMethodTime"
              label="公司结算时间"
              width="180">
          </el-table-column>
          <el-table-column
              prop="declareMethodName"
              label="申报支付方式"
              width="120">
          </el-table-column>
          <el-table-column
              prop="declareMethodTime"
              label="申报支付时间"
              width="180">
          </el-table-column>
          <el-table-column
              label="申报日期"
              width="120">
            <template slot-scope="scope">{{ scope.row.declarationDate }}</template>
          </el-table-column>
          <el-table-column
              label="操作"
              width="300"
              fixed="right">
            <template slot-scope="scope">
              <el-button
                  type="primary"
                  size="mini"
                  @click="handlePicture(scope.row)"
                  style="line-height: 8px; padding: 4px 4px;">支出图片</el-button>

              <el-button
                  type="primary"
                  size="mini"
                  @click="handleEdit(scope.row)"
                  style="line-height: 8px; padding: 4px 8px;">编辑</el-button>

              <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row.id)"
                  style="line-height: 8px; padding: 4px 8px;">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="24">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size="100"
            layout="prev, pager, next, total, jumper, sizes"
            :total="table.total">
            style="margin-top: 10px">
        </el-pagination>
      </el-col>
    </el-row>

    <spend-picture :visible.sync="showPicture" :id="spendId" v-if="showPicture" :spend-image="spendImage"
                   @close="querySpendListByCondition"></spend-picture>
    <spend-edit :visible.sync="editVisible" :spend="spend" v-if="editVisible"
                @close="querySpendListByCondition"></spend-edit>
  </div>
</template>

<script>
import SpendPicture from "@/pages/spend/spend/spend-image";
import SpendEdit from "@/pages/spend/spend/spend-edit";
export default {
  name: "spend-list",
  components: {SpendEdit, SpendPicture},
  created() {
    this.pageInit()
  },
  data() {
    return {
      props: { multiple: true },
      projectsArray: {},

      table:{
        tableData: [],
        page:1,
        limit:100,
        total:0,
      },
      spendImage: '',
      showPicture: false,
      editVisible: false,
      spendId: 0,
      spend: {},
      // 状态：结算/预支
      state: [
        {
          value: '0',
          name: '预支'
        },
        {
          value: '1',
          name: '结算'
        }
      ],
      declareMethodArray: [],
      spendMethodArray: [],
      shops: [],
      ids: [],
      form: {
        // 申报日期
        declarationDateList: [],
        // 申报支付日期
        declarePaymentDateList: [],
        // 支出发生日期
        createDateList: [],
        // 公司结算日期
        settlementDateList: [],
        // 内容
        content: '',
        // 报销人
        spendPeople: '',
        // 当前选中的状态
        settlement: '',
        // 支出项目
        spendProjectsIds: [],
        // 店铺
        shopIds: [],
        // 公司结算方式
        spendMethodIds: [],
        // 申报支付方式
        declareMethodIds: [],
      },
    }
  },
  methods: {
    // 通过条件查询列表
    querySpendListByCondition:function (){
      this.$axios({
        method:"GET",
        url: "/spend/listByCondition",
        params: {
          page:this.table.page,
          limit:this.table.limit,
          tenantCrop: localStorage.getItem('tenantCrop'),
          ...this.form
        }
      }).then(response => {
        this.table.total = response.data.data.total
        this.table.tableData = response.data.data.list
      })
    },
    // 重置搜索条件
    reset() {
      this.form.declarationDateList = [];
      this.form.declarePaymentDateList = [];
      this.form.createDateList = [];
      this.form.settlementDateList = [];

      this.form.content = '';
      this.form.spendPeople = '';
      this.form.settlement = '';
      this.form.spendProjectsIds = [];
      this.form.shopIds = [];
      this.form.spendMethodIds = [];
      this.form.declareMethodIds = [];
      this.querySpendListByCondition()
    },
    handleSizeChange(val) {
      this.table.limit = val;
      this.querySpendListByCondition();
    },
    handleCurrentChange(val) {
      this.table.page = val;
      this.querySpendListByCondition();
    },
    // 页面初始化
    pageInit() {
      this.queryShopIds()
      this.querySpendProjectsIds()
      this.querySpendMethodIds()
      this.queryDeclareMethodIds()
      this.querySpendListByCondition()
    },
    // 店铺信息
    queryShopIds() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shops = JSON.parse(response.data.data)
      })
    },
    /* 搜索支出项目 */
    querySpendProjectsIds() {
      this.$selectUtils.queryProjectsByType(this.$projectsType.category).then(response => {
        this.projectsArray = JSON.parse(response.data.data)
      })
    },
    // 公司结算方式
    querySpendMethodIds() {
      this.$selectUtils.queryAccountIds(1).then(response => {
        this.spendMethodArray = JSON.parse(response.data.data)
      })
    },
    // 申报支付方式
    queryDeclareMethodIds() {
      this.$selectUtils.queryAccountIds(0).then(response => {
        this.declareMethodArray = JSON.parse(response.data.data)
      })
    },
    // 批量删除
    deleteBatch() {
      if (this.ids.length === 0) {
        this.$message.error('请先选择要删除的支出')
        return false
      }
      this.$confirm('此操作将永久删除该支出, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteSpendByIds()
      })
    },
    // 支出图片
    handlePicture(row) {
      this.showPicture = true
      this.spendId = row.id
      this.spendImage = row.spendImage
    },
    // 编辑
    handleEdit(row) {
      this.spend = row
      this.editVisible = true
    },
    // 单个删除
    handleDelete(id) {
      this.ids = []
      this.ids.push(id)
      this.$confirm('此操作将永久删除该支出, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteSpendByIds()
      })
    },
    handleSelect(selection) {
      this.ids = []
      selection.forEach(item => {
        this.ids.push(item.id)
      })
    },
    // 删除支出
    deleteSpendByIds() {
      this.$axios({
        method: 'POST',
        url: '/spend/deleteSpendByIds',
        data: this.ids
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success('删除成功')
          this.querySpendListByCondition()
        } else {
          this.$message.error(response.data.msg)
        }
        this.ids = []
      })
    }
  }
}
</script>

<style scoped>
.el-divider__text {
  font-size: 20px;
}
</style>